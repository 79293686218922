import React, { useEffect, useState } from 'react'
import ApexCharts from 'react-apexcharts'
import { useAllActiveAlertsForGateway } from '../../hook/useAlerts'
import { CardHeader, Skeleton } from '@mui/material'
import Alerts from '../../common-images/app-logo/customer-images/Alerts'
import OnlineOfflineImage from '../../common-images/onlineOfflineImage'
import { COLORSHITRON } from '../../utils/colorsHitron'
import { COLORS } from '../../utils/colors'

interface PieChartsProps {
  gwuid: string // Add a type annotation for the fillColor prop
}

const PieCharts: React.FC<PieChartsProps> = ({ gwuid }) => {
  const { data, isLoading, refetch } = useAllActiveAlertsForGateway(gwuid)
  const [total, setTotal] = useState(0)

  React.useEffect(() => {
    if (!!gwuid) {
      refetch() // Manually trigger the query when the condition is met
    }
  }, [gwuid])

  useEffect(() => {
    if (data) {
      if (data !== 401) {
        setTotal(data?.reduce((acc: any, series: any) => acc + series.data, 0))
      }
    }
  }, [data])
  // Calculate the total count

  const options = {
    series: data && data !== 401 && data?.length !== 0 && data?.map((series: any) => series.data),
    labels: data && data !== 401 && data?.length !== 0 && data?.map((series: any) => series.name),
    chart: {
      type: 'donut'
    },
    dataLabels: {
      enabled: false // Set this to false to hide data labels (percentage labels)
    },
    plotOptions: {
      pie: {
        expandOnClick: false,
        donut: {
          labels: {
            show: false,
            total: {
              show: false, // Show the total count in the center
              formatter: function (w: any) {
                return total.toString() // Display the total count
              },
              label: 'Alerts'
            }
          }
        }
      }
    },
    legend: {
      show: false // This will hide the legend
    },
    colors: process.env.REACT_APP_COLOR === 'hitron' ? COLORSHITRON : COLORS,
    tooltip: {
      enabled: false
    }
  }

  return (
    <>
      {isLoading ? (
        <>
          <Skeleton variant='circular' width={'80%'} height={'80%'} />
        </>
      ) : (
        <>
          <div>
            <CardHeader
              avatar={<Alerts />}
              action={<OnlineOfflineImage fillColor='#e7e8ea' />}
              title='Alerts'
              subheader='0'
              className='ps-4 status-box pe-4 pt-4'
            />
          </div>
          {/* {data && data?.length !== 0 && (data !== 401 ) && (
            <>
              <div>
                <ApexCharts options={options as any} series={options.series} type='donut' className='charttext' />
              </div>
              <div className='d-flex ps-4 ms-2 mt-2'>
                <div className='d-grid pe-2'>
                  <label className='text-center alert-chart-count'></label>
                  <label className='text-center alert-chart-name'>Critical</label>
                  <div className='alert-progress alert-progress-critical mt-1 bg-secondary'></div>
                </div>
                <div className='d-grid pe-2'>
                  <label className='text-center alert-chart-count'></label>
                  <label className='text-center alert-chart-name'>Warning</label>
                  <div className='alert-progress alert-progress-warning mt-1 bg-secondary'></div>
                </div>
                <div className='d-grid pe-2'>
                  <label className='text-center alert-chart-count'></label>
                  <label className='text-center alert-chart-name'>Info</label>
                  <div className='alert-progress alert-progress-problem mt-1 bg-secondary'></div>
                </div>
              </div>
            </>
          )} */}
        </>
      )}
    </>
  )
}

export default PieCharts
