import { Modal } from 'antd'
import { Button } from '@mui/material'
import { Close } from '@mui/icons-material'
import moment from 'moment'
import { useState } from 'react'
import { getColorCode } from '../../../utils/helper'

const UpdateFirmware = ({ isOpen, onClose, onSubmit, title, subTitle, data, customerinfo }: any) => {
  const [confirmation, setConfirmation] = useState(false)
  // const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [selected, setSelected] = useState('')

  return (
    <>
      <Modal
        open={isOpen}
        footer={null}
        closable={false}
        width={500}
        wrapClassName={'reset_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3
              style={{ fontSize: '20px', fontWeight: '600', width: '100%', display: 'flex', justifyContent: 'center' }}
            >
              {title}
            </h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            {!data && <div className='mb-3 mt-3 text-lg font-bold'>Data not available</div>}
            {data?.length === 0 && <div className='mb-3 mt-3 text-lg font-bold'>Already updated</div>}
            {/* <div className='mb-1 mt-1 text-xl font-normal'>Live Versions</div> */}
            {data?.length !== 0 && selected === '' && <div className='text-sm font-normal'>Select the version</div>}
            {data?.length !== 0 &&
              data
                ?.sort((a: any, b: any) => new Date(b.date).getTime() - new Date(a.date).getTime())
                .map((obj: any, key: any) => {
                  return (
                    <div
                      className={`border mb-3 pb-15 pt-3  ${selected === obj.id ? 'bg-blue-200' : ''} `}
                      key={key}
                      onClick={() => {
                        setSelected(obj.id)
                      }}
                    >
                      <div className={`flex justify-between pl-8 pr-8 text-sm font-normal`}>
                        <div>
                          {obj.fw_version} {customerinfo?.fw_version === obj.fw_version ? '*' : ''}
                        </div>
                        <div>
                          {moment.utc(obj.date).local().format(`${process.env.REACT_APP_DATE_FORMAT}`)}
                          {/* {moment.tz(moment.utc(obj.date), timeZone).format(`${process.env.REACT_APP_DATE_FORMAT}`)}  */}
                        </div>
                      </div>
                    </div>
                  )
                })}

            {data && data?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    setConfirmation(true)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{
                    marginRight: '10px',
                    color: 'white',
                    background: getColorCode('infoBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('infoBlue')
                    }
                  }}
                  disabled={selected === ''}
                >
                  Update
                </Button>
                {/* <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{
                    color: getColorCode('infoBlue'),
                    borderColor: getColorCode('infoBlue')
                  }}
                >
                  Cancel
                </Button> */}
              </div>
            )}
          </div>
        </div>
      </Modal>
      <Modal
        open={confirmation}
        footer={null}
        closable={false}
        width={300}
        wrapClassName={'reset_confirmation_modal_wrapper'}
        centered={true}
      >
        <div className='reset_modal_body_wrapper device-tab'>
          <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
            <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>Confirm</h3>
            <Close
              className='cursor-pointer'
              onClick={() => {
                onClose()
              }}
            />
          </div>
          <div className='mb-2 row-gap-1 text-center'>
            <h4>Are you sure you want to update the FW?</h4>

            {data && data?.length !== 0 && (
              <div className='mt-3'>
                <Button
                  onClick={e => {
                    e.preventDefault()
                    onSubmit(selected)
                  }}
                  variant='contained'
                  size='medium'
                  sx={{
                    marginRight: '10px',
                    background: getColorCode('infoBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('infoBlue')
                    }
                  }}
                  disabled={selected === ''}
                >
                  Ok
                </Button>
                {/* <Button
                  onClick={() => onClose()}
                  size='medium'
                  variant='outlined'
                  sx={{
                    color: getColorCode('infoBlue'),
                    borderColor: getColorCode('infoBlue')
                  }}
                >
                  Cancel
                </Button> */}
              </div>
            )}
          </div>
        </div>
      </Modal>
    </>
  )
}

export default UpdateFirmware
