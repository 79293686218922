import React, { useEffect, useState, useRef, useMemo } from 'react'
import { useSelector } from 'react-redux'
import moment from 'moment'
import * as THREE from 'three'
import SpriteText from 'three-spritetext'
import { Box, Button, FormControlLabel, Skeleton, Snackbar, Switch } from '@mui/material'
import { ArrowLeft2, ArrowRight2 } from 'iconsax-react'
import MuiAlert from '@mui/material/Alert'
import { ForceGraph3D } from 'react-force-graph'
import { DatePicker, Modal } from 'antd'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import CloseIcon from '@mui/icons-material/Close'
import styles from './styles.module.scss'
import './index.scss'
import { getColorCode } from '../../../utils/helper'
import { newhandleNetworkHealthMapFingerPrinting } from '../../../utils/fingerprinting'
import { useConnectedDevices } from '../../../hook/useConnectedDevices'
import NetworkMapPopover from '../../../components/popover/popover'
import { useSessionContex } from '../../../SessionContex'
import { useGatewayInfo } from '../../../hook/useGatewayInfo'
import Loader from '../../../components/Loader'
import {
  useConditionalParentalConnectedDevices,
  useParentaDeletePCSetting
} from '../../../hook/parental-control/useParental'
import Topology from '../Topology'

dayjs.extend(utc)
dayjs.extend(timezone)

const statusIcon = type => {
  return (
    <svg width='16' height='14' viewBox='0 0 16 14' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M14.4666 4.472C15.3697 6.03633 15.3697 7.96367 14.4666 9.528L13.4226 11.3362C12.5194 12.9005 10.8503 13.8642 9.04397 13.8642L6.95603 13.8642C5.1497 13.8642 3.48058 12.9005 2.57741 11.3362L1.53345 9.528C0.630279 7.96367 0.630279 6.03633 1.53345 4.472L2.57741 2.6638C3.48058 1.09946 5.1497 0.135796 6.95604 0.135797L9.04397 0.135797C10.8503 0.135797 12.5194 1.09946 13.4226 2.6638L14.4666 4.472Z'
        fill={getColorCode(type)}
        stroke='#6B7A9F'
        stroke-width='0.128'
      />
    </svg>
  )
}
function Alert(props) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const TopologyNew = ({ serialNumber, sendMqttMessage }) => {
  const { timeZone } = Intl.DateTimeFormat().resolvedOptions()
  const gwuid = useSelector(state => state.customerStatistics.activeGw_Uid)
  const graphRef = useRef(null)

  const { reCall, setReCall } = useSessionContex()
  const { data: gwData, refetch: gatewayInfoRef } = useGatewayInfo(gwuid)
  const [selectedDateTime, setSelectedDateTime] = useState(dayjs())
  const [open, setOpen] = useState(false)
  const [openDevice, setOpenDevice] = useState(false)
  const [displayData, setDisplayData] = useState('')
  const [isRunning, setIsRunning] = useState(false)
  const [counter, setCounter] = useState(360)
  const [enableNodeDrag, setEnableNodeDrag] = useState(false)
  const [enableNavigationControls, setEnableNavigationControls] = useState(true)
  const [graphData, setGraphData] = useState({ nodes: [], links: [] })
  const [isGraph, setIsGraph] = useState(true)
  const [snackBarInfo, setSnackBarInfo] = useState({
    isOpen: false,
    message: '',
    type: 'Closed'
  })

  const {
    data: networkMapData,
    isLoading: networkMapDataLoading,
    refetch: refetchNetworkMap,
    isFetching
  } = useConnectedDevices(serialNumber || gwuid, selectedDateTime?.toISOString())
  const { isFetching: isFetchingPCSetting, refetch, data } = useParentaDeletePCSetting(displayData)
  const {
    data: deviceData,
    isFetching: deviceFetching,
    refetch: deviceRefetch
  } = useConditionalParentalConnectedDevices(
    moment.utc(moment.tz(moment(selectedDateTime), timeZone)).format('YYYYMMDDTHH:mm:ss'),
    // selectedDateTime.toISOString(),
    gwData?.email
  )
  useEffect(() => {
    // Event handler function
    const handleVisibilityChange = () => {
      if (document.visibilityState === 'visible') {
        // alert('Welcome back to the tab!')
        setOpenDevice(false)
        setOpen(false)
        setSelectedDateTime(dayjs())
        setIsGraph(true)
      }
    }
    // Add event listener for visibilitychange
    document.addEventListener('visibilitychange', handleVisibilityChange)
    // Clean up event listener on component unmount
    return () => {
      document.removeEventListener('visibilitychange', handleVisibilityChange)
    }
  }, [])
  useEffect(() => {
    if (!isFetchingPCSetting && data && data?.http_code === 200) {
      setTimeout(() => {
        setOpenDevice(false)
        setSnackBarInfo({
          isOpen: true,
          message: data.status,
          type: 'Closed'
        })
        setIsGraph(true)
        refetchNetworkMap()
        deviceRefetch()
      }, 1000)
    }
  }, [data, isFetchingPCSetting])

  useEffect(() => {
    gatewayInfoRef()
  }, [serialNumber, gwuid])

  useEffect(() => {
    if (counter === 0) {
      setIsRunning(false)
      setCounter(360)
    }
  }, [counter])
  useEffect(() => {
    let intervalId

    if (isRunning) {
      intervalId = setInterval(() => {
        setCounter(prevCounter => prevCounter - 1)
      }, 1000)
    } else {
      clearInterval(intervalId)
    }

    return () => clearInterval(intervalId)
  }, [isRunning])

  useEffect(() => {
    if (gwData) {
      setIsGraph(true)
      refetchNetworkMap()
      deviceRefetch()

      if (gwData && gwData?.is_online) {
        const interval = setInterval(() => {
          setIsGraph(true)
          setSelectedDateTime(dayjs())
        }, 2 * 60 * 1000) // 2 minutes in milliseconds
        return () => clearInterval(interval)
      }
    }
  }, [selectedDateTime])

  useEffect(() => {
    if (gwData) {
      if (gwData?.is_online) {
        setSelectedDateTime(dayjs())
      } else {
        setSelectedDateTime(dayjs.utc(gwData?.last_seen).local())
      }
    }
  }, [gwData])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  useEffect(() => {
    if (reCall) {
      setSelectedDateTime(dayjs())
      setIsGraph(true)
    }
  }, [reCall])

  function getLineStyle(link) {
    if (link.client_conn_type !== 'ETHERNET') {
      if (link.channel > 15 || link.mesh_role === 'satellite') {
        return 'dot' // 8 dot
      } else {
        return 'dash' // 2 dot
      }
    } else {
      return 'solid' // Solid line
    }
  }
  const displayMode = mode => {
    // 11AC - Wifi5
    // 11AXA, 11AXG Wifi 6
    // 11BEA, 11BEG - Wifi 7
    // 11A, 11B, 11G, FH, TURBO, 11NA , 11NG = wifi4
    // unknown = wifi4
    // let wifiType
    let svgTexture = ''
    switch (mode) {
      case '11AC':
        svgTexture = 'wifi5.png'
        // wifiType = 'wifi5'
        break
      case '11AXA':
      case '11AXG':
        svgTexture = 'wifi6.png'
        // wifiType = 'wifi6'
        break
      case '11A':
      case '11B':
      case '11G':
      case 'FH':
      case 'TURBO':
      case '11NA':
      case '11NG':
      case 'unknown':
        svgTexture = 'wifi4.png'
        // wifiType = 'wifi4'
        break
      default:
        svgTexture = ''
        break
    }
    return svgTexture
  }

  useEffect(() => {
    // Function to create a node
    if (
      !deviceFetching &&
      !networkMapDataLoading &&
      networkMapData &&
      networkMapData !== 401 &&
      networkMapData !== undefined
    ) {
      if (networkMapData && networkMapData.length === 0) {
        setGraphData({ nodes: [], links: [] })
      }
      networkMapData &&
        networkMapData.length !== 0 &&
        networkMapData?.forEach((obj, index) => {
          let deviceMacData = []
          let pc_active = false

          if (process.env.REACT_APP_REALM === 'Hitron-staging') {
            deviceMacData =
              deviceData && deviceData.length !== 0 ? deviceData?.filter(obja => obja?.device_mac === obj?.mac) : []

            if (deviceMacData?.length !== 0) {
              if (
                deviceMacData[0].blocked_categories_count === 0 &&
                deviceMacData[0].blocked_domains_count === 0 &&
                deviceMacData[0].internet_blocked_till === null &&
                deviceMacData[0].internet_enabled === true &&
                deviceMacData[0].internet_restrictions_schedule_enabled === false
              ) {
                pc_active = false
              } else {
                pc_active = true
              }

              obj.device_id = deviceMacData[0].device_id
            } else {
              obj.device_id = ''
            }
          } else {
            deviceMacData =
              deviceData && deviceData?.devices && deviceData?.devices?.length !== 0
                ? deviceData?.devices?.filter(obja => {
                    return obja?.device_macid?.toLowerCase() === obj?.mac
                  })
                : []

            if (deviceMacData?.length !== 0) {
              obj.device_id = deviceMacData[0].device_id
              if (deviceMacData[0].is_pc_active) {
                pc_active = true
              } else {
                pc_active = false
              }
            } else {
              obj.device_id = ''
            }
          }

          obj.pc_active = pc_active
          obj.index = index
        })
      function createNode(device) {
        return {
          // id: device.mac,
          id: `${device.index}`,
          x: Math.random() * 100,
          y: Math.random() * 100,
          z: Math.random() * 100,
          // Add other node properties here (e.g., radius, color based on device_type)
          deviceType: device.device_type,
          deviceName: device.device_name,
          client_conn_type: device.client_conn_type,
          signal_strength: device.signal_strength,
          channel: device.channel,
          mesh_role: device.mesh_role,
          title: device.title,
          host: device.host,
          ssid: device.ssid,
          device_name: device.device_name,
          mac: device.mac,
          rate: device.rate,
          rssi: device.rssi,
          ip: device.ip,
          parent: device.parent,
          pc_active: device.pc_active,
          device_id: device.device_id,
          mode: displayMode(device.mode),
          image:
            device.mesh_role === 'master'
              ? 'mainWifi.png'
              : device.mesh_role === 'satellite'
              ? 'satellite.png'
              : device?.model?.toLowerCase()?.includes('aria')
              ? 'aria.png'
              : process.env.REACT_APP_REALM === 'Hitron-staging'
              ? `${device?.device_icon || 'unknown'}.png`
              : `${newhandleNetworkHealthMapFingerPrinting([
                  device?.os_name,
                  device?.device_name,
                  device?.fingerbank_device_name,
                  device?.os_parent_name,
                  device?.manufacturer_name
                ])}2x.png`
        }
      }

      // Function to create a link
      function createLink(parentDevice, childDevice) {
        const lineStyle = getLineStyle(childDevice)
        let linkDash = 0
        switch (lineStyle) {
          case 'dash':
            linkDash = 2 // Example: 5px dashes, 10px gaps
            break
          case 'dot':
            linkDash = 8 // Example: 2px dots, 4px gaps
            break
          default: // Default to solid line
            break
        }

        return {
          source: `${parentDevice.index}`,
          target: childDevice.id,
          color:
            childDevice.client_conn_type === 'ETHERNET'
              ? getColorCode('SIG_STR_HIGH')
              : getColorCode(childDevice.signal_strength),
          value: linkDash,
          channel: childDevice.channel || '',
          mode: childDevice.mode
        }
      }

      // Process network data to build graphData
      const nodes = []
      const links = []

      const masterDevice = networkMapData?.find(device => device.mesh_role === 'master')

      if (!masterDevice) {
        setTimeout(() => {
          setIsGraph(false)
        }, 1500)
        return
      }

      const masterNode = createNode(masterDevice)
      nodes.push(masterNode)

      let childNodes = []

      function processChildren(parentDevice, linkedChildNodes = new Set()) {
        childNodes = networkMapData?.filter(device => {
          return device.parent === parentDevice.mac
        })

        // for (const childDevice of childNodes) {
        //   const childNode = createNode(childDevice)
        //   nodes.push(childNode)
        //   links.push(createLink(parentDevice, childNode))
        //   processChildren(childDevice)
        // }
        for (const childDevice of childNodes) {
          const childNode = createNode(childDevice)
          nodes.push(childNode)
          const link = createLink(parentDevice, childNode)
          links.push(link)
          linkedChildNodes.add(childDevice.mac) // Add child to the set of linked child nodes
          processChildren(childDevice, linkedChildNodes)
        }
      }

      processChildren(masterDevice)

      const processedGraphData = {
        nodes,
        links
      }

      setGraphData(processedGraphData)
      // if (graphRef && graphRef?.current) {
      //   graphRef?.current?.zoomToFit(0, 0)
      // }
      setTimeout(() => {
        setIsGraph(false)
      }, 1500)
      // setTimeout(() => {
      //   if (graphRef?.current) {
      //     graphRef?.current?.zoomToFit(0, 20) // Adjust parameters as per your graph's API
      //   }
      // }, 1100)
    }
  }, [networkMapData, deviceData, networkMapDataLoading, deviceFetching])

  const onChange = (date, dateStr) => {
    const currentDateTime = dayjs.tz(dayjs(), timeZone)

    // Get the selected date and time
    const selectedDateTimeObj = dayjs.tz(date, timeZone)

    if (selectedDateTimeObj.isAfter(currentDateTime)) {
      //console.log('=======>>@@', date)
    } else {
      setSelectedDateTime(date)
    }
  }
  const prevDate = e => {
    e.preventDefault()
    setSelectedDateTime(dayjs.tz(selectedDateTime, timeZone).subtract(1, 'minutes'))
  }
  const nextDate = e => {
    e.preventDefault()
    if (gwData?.is_online) {
      const currentDateTime = dayjs.tz(dayjs(), timeZone)

      // Get the selected date and time
      // const selectedDateTimeObj = dayjs.tz(selectedDateTime, timeZone).add(1, 'minutes')

      // if (selectedDateTimeObj.isAfter(currentDateTime)) {
      const selectedDateTimeObj = dayjs.tz(selectedDateTime, timeZone)
      if (currentDateTime.format('YYYY-MM-DD HH:mm') === selectedDateTimeObj.format('YYYY-MM-DD HH:mm')) {
      } else {
        setSelectedDateTime(dayjs.tz(selectedDateTime, timeZone).add(1, 'minutes'))
      }
    } else {
      const currentDateTime = dayjs.tz(dayjs.utc(gwData?.last_seen), timeZone)
      const selectedDateTimeObj = dayjs.tz(selectedDateTime, timeZone)
      if (currentDateTime.format('YYYY-MM-DD HH:mm') === selectedDateTimeObj.format('YYYY-MM-DD HH:mm')) {
      } else {
        setSelectedDateTime(dayjs.tz(selectedDateTime, timeZone).add(1, 'minutes'))
      }
    }
  }
  const jumpDate = e => {
    e.preventDefault()
    if (gwData?.is_online) {
      const currentDateTime = dayjs.tz(dayjs(), timeZone)

      // Get the selected date and time
      const selectedDateTimeObj = dayjs.tz(selectedDateTime, timeZone)
      if (currentDateTime.format('YYYY-MM-DD HH:mm') === selectedDateTimeObj.format('YYYY-MM-DD HH:mm')) {
      } else {
        setSelectedDateTime(dayjs())
      }
    } else {
      const currentDateTime = dayjs.tz(dayjs.utc(gwData?.last_seen), timeZone)
      const selectedDateTimeObj = dayjs.tz(selectedDateTime, timeZone)
      if (currentDateTime.format('YYYY-MM-DD HH:mm') === selectedDateTimeObj.format('YYYY-MM-DD HH:mm')) {
      } else {
        setSelectedDateTime(dayjs.utc(gwData?.last_seen).local())
      }
    }
  }

  const onSubmit = () => {
    if (displayData !== '') {
      refetch()
      setDisplayData('')
    }
  }

  const handleNodeClick = node => {
    if (node?.pc_active) {
      if (process.env.REACT_APP_REALM === 'Hitron-staging') {
        setDisplayData(node?.mac)
      } else {
        setDisplayData(node?.device_id)
      }
      setOpenDevice(true)
    }
  }

  const NodeImage = node => {
    const container = new THREE.Object3D()
    const textName =
      node?.mesh_role === 'master'
        ? node?.title || node?.host || node?.ssid || node?.device_name || 'N/A'
        : node?.mesh_role !== 'master' && node?.mesh_role !== 'satellite'
        ? node.host || node.ssid || node.device_name || 'N/A'
        : node?.title || node?.host || node?.ssid || node?.device_name || 'N/A'

    const spriteText = new SpriteText(`${textName?.length > 5 ? `${textName?.substring(0, 5)}..` : textName}`)
    spriteText.color = '#fff'
    spriteText.backgroundColor = '#383838'
    spriteText.borderRadius = 3
    spriteText.padding = 1
    spriteText.textHeight = 1
    spriteText.position.set(0, 6, 0)
    container.add(spriteText)

    const imgTexture = new THREE.TextureLoader().load(
      // `/images/network-map-${folder.toLowerCase() !== 'xunison' ? 'new-hitron-images' : 'images'}/${node.image}`
      `/images/network-map-new-hitron-images/${node.image}`
    )
    imgTexture.colorSpace = THREE.SRGBColorSpace
    const material = new THREE.SpriteMaterial({ map: imgTexture })

    const sprite = new THREE.Sprite(material)
    sprite.scale.set(9, 9)
    sprite.position.set(0, 0, 0)
    container.add(sprite)

    return container
  }

  const nodeDisplayLabel = device => {
    return `<div style="color: white !important; background-color:black !important; padding-left:5px; padding-right:5px; border-radius:5px;">${
      device?.mesh_role !== 'master' && device?.mesh_role !== 'satellite'
        ? `${`${device?.client_conn_type !== 'ETHERNET' ? `Channel: ${device?.channel || '-'} <br />` : ''}`} IP: ${
            device?.ip || '-'
          } <br /> Connection type: ${device?.client_conn_type || '-'} ${
            device?.pc_active
              ? `<br /> Parental Limits: <i class='fa fa-lock' style="color: white !important;"></i>`
              : ``
          } ${`<br/> Name: ${
            device.host || device.ssid || device.device_name || 'N/A'
          }`}${`<br /> Mac: ${device?.mac}`}  ${
            device?.rate || device?.rssi
              ? `<br /> dBm / rssi: ${
                  device?.mesh_role !== 'master' && device?.rssi
                    ? `(${device?.rssi} dBm / ${device?.rate} Mbps)`
                    : `${device?.rate} Mbps`
                }
              `
              : ''
          }`
        : device?.mesh_role === 'master'
        ? `Mesh role: ${device?.mesh_role} <br/> SSID: ${device?.ssid} <br/> Name: ${
            device?.title || device?.host || device?.ssid || device?.device_name || 'N/A'
          } <br /> Mac: ${device?.mac}`
        : `IP: ${device?.ip || '-'} <br/> Name: ${
            device?.title || device?.host || device?.ssid || device?.device_name || 'N/A'
          } <br /> Mac: ${device?.mac} <br/> Mesh Role: ${device?.mesh_role} <br/> RSSI: ${device?.rssi}`
    }</div>`
  }

  const MyComponent = useMemo(() => {
    return (
      <ForceGraph3D
        width={'100%'}
        ref={graphRef}
        graphData={graphData}
        showNavInfo={false}
        backgroundColor='white'
        nodeThreeObject={NodeImage}
        enableNodeDrag={enableNodeDrag}
        enableNavigationControls={enableNavigationControls}
        nodeLabel={device => nodeDisplayLabel(device)}
        linkWidth={0.2}
        linkDirectionalParticles='value'
        linkDirectionalParticleSpeed={d => d.value * 0.001}
        linkThreeObjectExtend={true}
        linkThreeObject={link => {
          const container = new THREE.Object3D()
          if (link.mode) {
            const imgTexture1 = new THREE.TextureLoader().load(`/images/${link.mode}`)
            imgTexture1.colorSpace = THREE.SRGBColorSpace
            const material1 = new THREE.SpriteMaterial({ map: imgTexture1 })

            const imageIprite = new THREE.Sprite(material1)
            imageIprite.scale.set(3.5, 3.5)

            container.add(imageIprite)
          }
          return container
        }}
        linkPositionUpdate={(sprite, { start, end }) => {
          const middlePos = Object.assign(
            ...['x', 'y', 'z'].map(c => ({
              [c]: start[c] + (end[c] - start[c]) / 2
            }))
          )
          Object.assign(sprite.position, middlePos)
        }}
        onNodeClick={handleNodeClick}
      />
    )
  }, [isGraph, enableNodeDrag, enableNavigationControls])

  // if (process.env.REACT_APP_ENV === 'prod') {
  //   return <Topology serialNumber={serialNumber} />
  // } else {
    return (
      <>
        {snackBarInfo?.isOpen && (
          <Snackbar
            open={snackBarInfo.isOpen}
            anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
            autoHideDuration={6000}
            onClose={() =>
              setSnackBarInfo({
                isOpen: false,
                message: snackBarInfo.message,
                type: snackBarInfo.type
              })
            }
            sx={{ backgroundColor: 'transparent' }}
          >
            <div>
              <Alert
                severity='success'
                sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
              >
                {snackBarInfo.message}
              </Alert>
            </div>
          </Snackbar>
        )}
        <div className='relative w-full'>
          <div
            className='flex items-center justify-between gap-x-6 w-full'
            style={{
              position: 'sticky',
              top: 'calc(83px)',
              left: 0,
              zIndex: 998,
              background: '#f7f8fe',
              padding: '10px'
            }}
          >
            <div className='flex flex-row gap-x-6 items-center'>
              <div>
                <NetworkMapPopover title='View'>
                  <div className={styles.inline_flex_gap_21}>
                    {statusIcon('SIG_STR_HIGH')}
                    <span>Status: Excellent</span>
                  </div>
                  <div className={styles.inline_flex_gap_21}>
                    {statusIcon('SIG_STR_MID')}
                    <span>Status: Ok</span>
                  </div>
                  <div className={styles.inline_flex_gap_21}>
                    {statusIcon('SIG_STR_LOW')}
                    <span>Status: Poor</span>
                  </div>
                  <div className={styles.inline_flex_gap_16}>
                    <svg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <circle cx='8' cy='2' r='2' fill='black'></circle>
                      <circle cx='20' cy='2' r='2' fill='black'></circle>
                      <line id='line' x1='0' y1='2' x2='30' y2='2' stroke='black'></line>
                    </svg>
                    <span>2.4GHz</span>
                  </div>
                  <div className={styles.inline_flex_gap_16}>
                    <svg width='30' height='4' viewBox='0 0 30 4' fill='none' xmlns='http://www.w3.org/2000/svg'>
                      <circle cx='4' cy='2' r='2' fill='black'></circle>
                      <circle cx='12' cy='2' r='2' fill='black'></circle>
                      <circle cx='20' cy='2' r='2' fill='black'></circle>
                      <circle cx='28' cy='2' r='2' fill='black'></circle>
                      <line id='line' x1='0' y1='2' x2='30' y2='2' stroke='black'></line>
                    </svg>
                    <span>5GHz</span>
                  </div>

                  <div className={styles.inline_flex_gap_10}>
                    <img src={`/images/wifi4.svg`} alt='wifi4' />

                    <span>Wi-Fi 4</span>
                  </div>
                  <div className={styles.inline_flex_gap_10}>
                    <img src={`/images/wifi5.svg`} alt='wifi5' />

                    <span>Wi-Fi 5</span>
                  </div>

                  <div className={styles.inline_flex_gap_10}>
                    <img src={`/images/wifi6.svg`} alt='wifi6' />
                    <span>Wi-Fi 6</span>
                  </div>
                  <FormControlLabel
                    control={<Switch checked={enableNodeDrag} onChange={() => setEnableNodeDrag(!enableNodeDrag)} />}
                    label='Drag Node'
                    disabled={isGraph || (graphData.nodes && graphData.nodes.length === 0)}
                  />
                  <FormControlLabel
                    control={
                      <Switch
                        checked={enableNavigationControls}
                        onChange={() => setEnableNavigationControls(!enableNavigationControls)}
                      />
                    }
                    label='Navigation Controls'
                    disabled={isGraph || (graphData.nodes && graphData.nodes.length === 0)}
                  />
                </NetworkMapPopover>
              </div>
              <div>
                <NetworkMapPopover title='Net Vision'>
                  <div className='mt-6 flex flex-col gap-y-3'>
                    <>
                      <Box display={'flex'} alignItems={'center'}>
                        <Button onClick={e => prevDate(e)} disabled={isGraph}>
                          <ArrowLeft2 />
                        </Button>
                        <DatePicker
                          onChange={onChange}
                          value={selectedDateTime}
                          disabled={isGraph}
                          format='YYYY-MM-DD HH:mm'
                          showTime={{ format: 'HH:mm' }}
                          allowClear={false}
                        />
                        <Button onClick={e => nextDate(e)} disabled={!gwData?.is_online && isGraph}>
                          <ArrowRight2 />
                        </Button>
                      </Box>
                      <Button onClick={e => jumpDate(e)} disabled={!gwData?.is_online && isGraph}>
                        Jump to now
                      </Button>
                    </>
                  </div>
                </NetworkMapPopover>
              </div>

              <div className='flex items-center'>
                Diagram Generated At :&nbsp;
                {
                  isGraph ? (
                    <Skeleton variant='text' animation='wave' width={80} height={15} />
                  ) : (
                    dayjs.utc(selectedDateTime).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  )
                  // gwData?.is_online ? (
                  //   moment.tz(new Date(selectedDateTime), timeZone).format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  // ) : (
                  //   moment.utc(gwData?.last_seen).local().format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  // )
                }
              </div>
              <Button
                onClick={() => {
                  setOpen(true)
                }}
                variant='contained'
                disabled={isRunning}
                sx={{
                  background: getColorCode('redToBlue'),
                  '&:hover': {
                    background: getColorCode('redToBlue')
                  }
                }}
              >
                ADD SATELLITE
              </Button>
              {isRunning ? (
                <div
                  style={{
                    color: '#fff',
                    padding: '6px 10px',
                    borderRadius: '50%',
                    fontWeight: 'bold',
                    background: getColorCode('redToBlue'),
                    '&:hover': {
                      backgroundColor: getColorCode('redToBlue')
                    }
                  }}
                >
                  {counter}
                </div>
              ) : (
                ''
              )}
            </div>
          </div>

          {!isGraph ? (
            graphData.nodes && graphData.nodes.length !== 0 ? (
              <div className='w-11/12 bg-white'>{MyComponent}</div>
            ) : (
              <></>
            )
          ) : (
            <Loader />
          )}

          <Modal open={open} footer={null} closable={false} wrapClassName={'delete_notes_wrapper'} centered={true}>
            <div className='delete_notes_body_wrapper device-tab'>
              <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                <h3
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  Add Satellite
                </h3>
                <CloseIcon
                  className='cursor-pointer'
                  onClick={() => {
                    setOpen(false)
                  }}
                />
              </div>
              <div className='mb-2 row-gap-1 text-center'>
                <div className='mb-3 mt-1 text-lg'>
                  Please hold pair button on your satellite router for 5 seconds until the LED light quick flashing
                  orange
                </div>
                <div>
                  <Button
                    onClick={() => {
                      const topology = JSON.stringify({
                        cmd: 'add_mesh_satellite'
                      })
                      sendMqttMessage(topology, gwuid)
                      setOpen(false)
                      if (counter > 0) {
                        setIsRunning(true)
                        setCounter(counter - 1)
                      }
                    }}
                    variant='contained'
                    size='medium'
                    sx={{
                      marginRight: '10px',
                      background: getColorCode('redToBlue'),
                      '&:hover': {
                        backgroundColor: getColorCode('redToBlue')
                      }
                    }}
                  >
                    Done
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
          <Modal
            open={openDevice}
            footer={null}
            closable={false}
            width={400}
            wrapClassName={'reset_modal_wrapper'}
            centered={true}
          >
            <div className='reset_modal_body_wrapper device-tab'>
              <div className='text-start d-flex justify-content-between align-items-center border-bottom mb-3 pb-3'>
                <h3
                  style={{
                    fontSize: '20px',
                    fontWeight: '600',
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center'
                  }}
                >
                  Parental Control Limits
                </h3>
                <CloseIcon
                  className='cursor-pointer'
                  onClick={() => {
                    setOpenDevice(false)
                  }}
                />
              </div>
              <div className='mb-2 row-gap-1 text-center'>
                <div className='mb-3 mt-1 text-base'>
                  Please confirm that you want to disable all parental control limits on this device.
                </div>
                <div>
                  <Button
                    onClick={onSubmit}
                    variant='contained'
                    size='medium'
                    sx={{
                      marginRight: '10px',
                      backgroundColor: getColorCode('blueBlue'),
                      '&:hover': {
                        backgroundColor: getColorCode('blueBlue')
                      }
                    }}
                    disabled={displayData === ''}
                  >
                    Disable
                  </Button>
                </div>
              </div>
            </div>
          </Modal>
        </div>
      </>
    )
  // }
}

export default TopologyNew
