import * as React from 'react'
import { Box, Button, useTheme } from '@mui/material'
import { getColorCode } from '../../utils/helper'
import { Close } from '@mui/icons-material'
import { useDeleteMSO, useKeyClockDeleteMSO } from '../../hook/useAllCustomers'
import { useSessionContex } from '../../SessionContex'

const DeleteMSO = ({ handleClose, setSnackBarInfo, editData, setDeviceOpenModal, setReCall, displaySearch }: any) => {
  const { openModal, setOpenModal } = useSessionContex()
  const [disable, setDisable] = React.useState<any>(false)
  const { data, refetch } = useDeleteMSO(editData?.id)

  const theme = useTheme()
  const deleteKeyClock = useKeyClockDeleteMSO()

  const onSubmit = () => {
    setDisable(true)
    if (displaySearch) {
      handleClose()
      setDisable(false)
    } else {
      refetch()
    }
  }
  React.useEffect(() => {
    if (data === 204 || data === 200) {
      console.log('========', editData)
      deleteKeyClock.mutate(editData?.white_label)
      setSnackBarInfo({
        isOpen: true,
        message: 'MSO Removed Successfully',
        type: 'Closed'
      })
      setTimeout(() => {
        setDeviceOpenModal(false)
        setReCall(true)
        handleClose()
        setDisable(false)
      }, 500)
    }
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
    if (data?.response && data?.response.statusText) {
      setSnackBarInfo({
        isOpen: true,
        message: `${data?.response.statusText}`,
        type: 'redToRed'
      })
    }
  }, [data])

  return (
    <Box className='rounded'>
      <Box
      // sx={{
      //   width: '500px',
      //   [theme.breakpoints.down('md')]: {
      //     width: 'auto'
      //   }
      // }}
      >
        <Box
          textAlign={'center'}
          display={'flex'}
          justifyContent={'space-between'}
          padding={2}
          className='border-bottom'
        >
          <h3 style={{ fontSize: '20px', fontWeight: '600', width: '100%' }}>
            Are you sure you want to delete this MSO?
          </h3>
          <Close
            className='cursor-pointer'
            onClick={() => {
              handleClose()
            }}
          />
        </Box>
        <Box className='pb-3 f8fe] pt-1 text-center' paddingLeft={5} paddingRight={5}>
          {displaySearch
            ? 'Please note, you will have to first remove all Devices assigned to this MSO, to delete it'
            : ''}
          <div className='mt-4 mb-3'>
            <Button
              type='submit'
              variant='contained'
              size='medium'
              sx={{
                marginRight: '10px',
                background: getColorCode('infoBlue'),
                '&:hover': {
                  backgroundColor: getColorCode('infoBlue')
                }
              }}
              onClick={onSubmit}
              disabled={disable}
            >
              {displaySearch ? 'OK' : 'DELETE'}
            </Button>
          </div>
        </Box>
      </Box>
      {/* <CustomerModal
        open={openListModal}
        handleClose={() => {
          setOpenListModal(false)
          setMessageArray([])
          setReCall(true)
          setCallDeviceDetail(true)
          handleClose()
        }}
      >
        <RemoveSuccess
          handleClose={() => {
            setOpenListModal(false)
            setMessageArray([])
            setReCall(true)
            setCallDeviceDetail(true)
            handleClose()
          }}
          data={messageArray}
        />
      </CustomerModal> */}
    </Box>
  )
}
export default DeleteMSO
