import React from 'react'
import { getColorCode } from '../../../utils/helper'

const Devices = () => {
  const color = getColorCode('infoBlue')

  return (
    <>
      <svg width='80' height='100' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M9.59432 7.17196C9.09821 7.65035 8.8 8.34547 8.8 9.21438V14.6787C8.8 14.8996 8.62091 15.0787 8.4 15.0787C8.17909 15.0787 8 14.8996 8 14.6787V9.21438C8 8.15473 8.36845 7.2427 9.03901 6.59609C9.70826 5.95074 10.6467 5.6001 11.7333 5.6001H13.0667C14.1533 5.6001 15.0917 5.95074 15.761 6.59609C16.4315 7.2427 16.8 8.15473 16.8 9.21438V15.0001C16.8 15.221 16.6209 15.4001 16.4 15.4001C16.1791 15.4001 16 15.221 16 15.0001V9.21438C16 8.34547 15.7018 7.65035 15.2057 7.17196C14.7083 6.69231 13.98 6.4001 13.0667 6.4001H11.7333C10.82 6.4001 10.0917 6.69231 9.59432 7.17196Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.4 13.6001C8.62091 13.6001 8.8 13.7792 8.8 14.0001V15.4287C8.8 16.4171 9.10647 17.2065 9.60909 17.745C10.1094 18.2811 10.8329 18.6001 11.7333 18.6001H13.0667C13.9671 18.6001 14.6906 18.2811 15.1909 17.745C15.6935 17.2065 16 16.4171 16 15.4287V14.0001C16 13.7792 16.1791 13.6001 16.4 13.6001C16.6209 13.6001 16.8 13.7792 16.8 14.0001V15.4287C16.8 16.5831 16.4398 17.5794 15.7758 18.2909C15.1094 19.0049 14.1662 19.4001 13.0667 19.4001H11.7333C10.6338 19.4001 9.69062 19.0049 9.02424 18.2909C8.3602 17.5794 8 16.5831 8 15.4287V14.0001C8 13.7792 8.17909 13.6001 8.4 13.6001Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.4 16.5C12.6762 16.5 12.9 16.2761 12.9 16C12.9 15.7239 12.6762 15.5 12.4 15.5C12.1239 15.5 11.9 15.7239 11.9 16C11.9 16.2761 12.1239 16.5 12.4 16.5ZM12.4 17C12.9523 17 13.4 16.5523 13.4 16C13.4 15.4477 12.9523 15 12.4 15C11.8477 15 11.4 15.4477 11.4 16C11.4 16.5523 11.8477 17 12.4 17Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.4 13.5C12.6762 13.5 12.9 13.2761 12.9 13C12.9 12.7239 12.6762 12.5 12.4 12.5C12.1239 12.5 11.9 12.7239 11.9 13C11.9 13.2761 12.1239 13.5 12.4 13.5ZM12.4 14C12.9523 14 13.4 13.5523 13.4 13C13.4 12.4477 12.9523 12 12.4 12C11.8477 12 11.4 12.4477 11.4 13C11.4 13.5523 11.8477 14 12.4 14Z'
          fill={color}
        />
      </svg>
    </>
  )
}

export default Devices
