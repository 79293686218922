import SecurityOverviewCommon from '../common/security-overview'
import SecurityOverviewStaging from '../staging/security-overview'
const ParentalControlTable: React.FC<any> = ({ email, mac }) => {
  return (
    <>
      {process.env.REACT_APP_REALM === 'Hitron-staging' ? (
        <SecurityOverviewStaging email={email} mac={mac} />
      ) : (
        <SecurityOverviewCommon email={email} mac={mac} />
      )}
    </>
  )
}

export default ParentalControlTable
