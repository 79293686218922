import { ChangeEvent, FC, useState } from 'react'
import { useCyberSendReportRequest, useCyberThreats } from '../../../hook/cyber-security/useCyberSecurity'

// mui
import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'
import { ArrowDown2, ArrowRight2 } from 'iconsax-react'
import moment from 'moment/moment'
import TablePagination from '@mui/material/TablePagination'
import Loader from '../../Loader'
import SessionTimeOutModal from '../../SessionTimeOutModal'
import { useSessionContex } from '../../../SessionContex'
import { Alert, Button, Snackbar } from '@mui/material'
import { getColorCode } from '../../../utils/helper'
import { SnackBarProps } from '../../Device-table/Provisioning'

function createData(name: string, calories: number, fat: number, carbs: number, protein: number, price: number) {
  return {
    name,
    calories,
    fat,
    carbs,
    protein,
    price,
    history: [
      {
        date: '2020-01-05',
        customerId: '11091700',
        amount: 3
      },
      {
        date: '2020-01-02',
        customerId: 'Anonymous',
        amount: 1
      }
    ]
  }
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props
  const [open, setOpen] = React.useState(false)

  return (
    <React.Fragment>
      <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
        <TableCell component='th' scope='row'>
          {row.name}
        </TableCell>
        <TableCell align='right'>{row.calories}</TableCell>
        <TableCell align='right'>{row.fat}</TableCell>
        <TableCell align='right'>{row.carbs}</TableCell>
        <TableCell align='right'>{row.protein}</TableCell>
        <TableCell>
          <IconButton aria-label='expand row' size='small' onClick={() => setOpen(!open)}>
            {open ? <ArrowRight2 /> : <ArrowDown2 />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={open} timeout='auto' unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <Typography variant='h6' gutterBottom component='div'>
                History
              </Typography>
              <Table size='small' aria-label='purchases'>
                <TableHead>
                  <TableRow>
                    <TableCell>Date</TableCell>
                    <TableCell>Customer</TableCell>
                    <TableCell align='right'>Amount</TableCell>
                    <TableCell align='right'>Total price ($)</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {row&&row?.history&&row?.history?.map(historyRow => (
                    <TableRow key={historyRow?.date}>
                      <TableCell component='th' scope='row'>
                        {historyRow?.date}
                      </TableCell>
                      <TableCell>{historyRow?.customerId}</TableCell>
                      <TableCell align='right'>{historyRow?.amount}</TableCell>
                      <TableCell align='right'>{Math.round(historyRow?.amount * row.price * 100) / 100}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  )
}

const rows = [
  createData('Frozen yoghurt', 159, 6.0, 24, 4.0, 3.99),
  createData('Ice cream sandwich', 237, 9.0, 37, 4.3, 4.99),
  createData('Eclair', 262, 16.0, 24, 6.0, 3.79),
  createData('Cupcake', 305, 3.7, 67, 4.3, 2.5),
  createData('Gingerbread', 356, 16.0, 49, 3.9, 1.5)
]
// mui
interface Props {
  email: string
  mac: string
  filteringDevice: any
  callApiLoader?: any
}

const CyberTable: FC<Props> = ({ email, mac, filteringDevice, callApiLoader }) => {
  const { openModal, setOpenModal } = useSessionContex()

  const [offset, setOffset] = useState<number>(0)
  const [offsetSummary, setSummaryOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(6)
  const [limitSummary, setSummaryLimit] = useState<number>(6)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [currentPageSummary, setSummaryCurrentPage] = useState<number>(0)
  const [filteredData, setFilteredData] = useState([])
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: 'Router Config Applied Successfully',
    type: 'Closed',
    severity: 'success'
  })

  const deviceFilter = typeof filteringDevice === 'object' ? filteringDevice?.device_id : filteringDevice
  const { data, isLoading, isFetching, refetch } = useCyberThreats(email, mac, deviceFilter, limit, currentPage)
  const { mutate: sendReportMutate, error: emailError, isSuccess: emailSuccess } = useCyberSendReportRequest()

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
  }

  const handleSummaryChangePage = (event: unknown, newPage: number) => {
    setSummaryCurrentPage(newPage)
    const newOffset = newPage * limitSummary
    setSummaryOffset(newOffset)
  }

  const handleSummaryChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    // setRowsPerPage(+event.target.value)
    setSummaryLimit(parseInt(event.target.value))
    setSummaryCurrentPage(0)
  }

  React.useEffect(() => {
    if (currentPage !== 0) {
      setCurrentPage(0)
    } else {
      if (!callApiLoader) {
        refetch()
        setFilteredData([])
      }
    }
  }, [filteringDevice, callApiLoader])

  // React.useEffect(() => {
  //   if (reCall) {
  //     if (currentPage !== 0) {
  //       setCurrentPage(0)
  //     } else {
  //       refetch()
  //       setFilteredData([])
  //     }
  //   }
  // }, [reCall])

  // React.useEffect(() => {
  //   if (!(isLoading || isFetching)) {
  //     if (reCall) {
  //       setTimeout(() => {
  //         setReCall(false)
  //       }, 500)
  //     }
  //   }
  // }, [isLoading, isFetching])

  React.useEffect(() => {
    if (!callApiLoader) {
      refetch()
      setFilteredData([])
    }
  }, [currentPage, limit])

  React.useEffect(() => {
    // const newFilteredData =
    //   filteringDevice && filteringDevice !== '*'
    //     ? data?.rows?.filter((item: any) => item.device_macid === filteringDevice)
    //     : data?.rows

    if (data === 401) {
      !openModal && setOpenModal(true)
    } else {
      setFilteredData(data?.rows)
    }
  }, [data])

  React.useEffect(() => {
    if (emailError !== null) {
      setSnackBarInfo({
        isOpen: true,
        message: emailError,
        type: 'redToRed',
        severity: 'error'
      })
    }
  }, [emailError])

  React.useEffect(() => {
    if (emailSuccess) {
      setSnackBarInfo({
        isOpen: true,
        message: 'Report Send Successfully',
        type: 'chartPurpleBlue'
      })
    }
  }, [emailSuccess])

  // const filteredData =
  //   filteringDevice && filteringDevice !== '*'
  //     ? data?.summary?.filter((item: any) => item.device_macid === filteringDevice)
  //     : data?.summary

  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  return (
    <div>
      {callApiLoader || isFetching ? (
        <Loader />
      ) : (
        <>
          <div className='font-bold text-base pl-5'>Summary</div>
          <table className='pc-table'>
            <thead>
              <tr>
                <td style={{ width: '17%' }}>Device</td>
                <td style={{ width: '15%' }}>Counts</td>
                <td style={{ width: '50%' }}>Attack Type</td>
                <td style={{ width: '25%' }}>Date</td>
              </tr>
            </thead>

            <tbody>
              {(!data || data?.summary?.length === 0) && (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                    {/* No summary detected on this device */}
                    No summary detected
                  </td>
                </tr>
              )}
              {data?.summary?.slice(offsetSummary, offsetSummary + limitSummary).map((item: any, index: number) => {
                // const time = moment(item.attack_date).format('D.M.YYYY hh:mm A').split(' ')
                const time = moment
                  .utc(item.last_attack_timestamp)
                  .local()
                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                // moment
                //   .tz(moment.utc(item.last_attack_timestamp), timeZone)
                //   .format(`${process.env.REACT_APP_DATE_FORMAT} HH:mm`)
                return (
                  <tr key={index}>
                    <td>
                      <div className='text-base '>
                        {item.device_name}
                        <br />
                        <span className=' text-sm' style={{ color: getColorCode('darkGreenToDarkPink') }}>
                          {item?.last_attack_url}
                        </span>
                      </div>
                    </td>
                    <td>{item.count}</td>
                    <td>{item.sig_name}</td>
                    <td>
                      {time}
                      {/* {time[0]}
                      <br />
                      {time[1] + ' ' + time[2]} */}
                    </td>
                  </tr>
                )
              })}
            </tbody>
          </table>
          {/* <TablePagination
            sx={{ marginRight: 0 }}
            rowsPerPageOptions={[3, 6, 10, 20, 50]}
            component='div'
            count={data?.summary?.length || 0}
            rowsPerPage={limitSummary}
            page={currentPageSummary}
            onPageChange={handleSummaryChangePage}
            onRowsPerPageChange={handleSummaryChangeRowsPerPage}
          /> */}

          <div className='font-bold text-base pl-5 mt-3'>Threats details</div>
          <table className='pc-table'>
            <thead>
              <tr>
                <td style={{ width: '32%' }}>Device</td>
                <td style={{ width: '50%' }}>Description</td>
                {process.env.REACT_APP_MODE !== 'hitron' && filteringDevice !== '*' && !isLoading && (
                  <td style={{ width: '10%' }}>Action</td>
                )}
                <td style={{ width: '25%' }}>Date</td>
              </tr>
            </thead>

            <tbody>
              {data?.rows?.length === 0 && (
                <tr>
                  <td colSpan={4} style={{ textAlign: 'center', color: getColorCode('redToBlue') }}>
                    {/* No Threats detected on this device */}
                    No threats detected
                  </td>
                </tr>
              )}
              {data?.rows
                // ?.slice(offset, offset + limit)
                ?.map((item: any, index: number) => {
                  const time = moment
                    .tz(moment.utc(item.attack_date), timeZone)
                    .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                  //  moment(item.attack_date).format('D.M.YYYY hh:mm A').split(' ')
                  // console.log(moment.tz(moment.utc(item.attack_date), timeZone).format('MM/DD` ${process.env.REACT_APP_DATE_FORMAT}THH:MM` HH:SS'))
                  return (
                    <tr key={item?.device_id}>
                      <td>
                        <div className='text-base '>
                          {item.device_name}
                          <br />
                          <span className=' text-sm' style={{ color: getColorCode('darkGreenToDarkPink') }}>
                            {item?.intrusion_url}
                          </span>
                          <span className='text-sm' style={{ color: getColorCode('darkRedToBlue') }}>
                            {' '}
                            ({item?.src_ip})
                          </span>
                          <br />
                          <span className='text-base'> {item.sig_name}</span>
                        </div>
                      </td>
                      <td>{item.sig_description}</td>
                      {process.env.REACT_APP_MODE !== 'hitron' && filteringDevice !== '*' && !isLoading && (
                        <td>
                         
                          <Button
                            onClick={async () => {
                              const res: any = await sendReportMutate({
                                payload: item,
                                email,
                                device_id: item?.device_id
                              })
                            }}
                            variant='contained'
                            size='medium'
                            sx={{
                              marginRight: '10px',
                              background: getColorCode('blueBlue'),
                '&:hover': {
                  backgroundColor: getColorCode('blueBlue')
                }
                            }}
                          >
                            Send Report
                          </Button>
                        </td>
                      )}
                      <td>
                        {time}
                        {/* {time[0]}
                        <br />
                        {time[1] + ' ' + time[2]} */}
                      </td>
                    </tr>
                  )
                })}
            </tbody>
          </table>
        </>
      )}
      {snackBarInfo.isOpen && (
        <Snackbar
          open={snackBarInfo.isOpen}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          autoHideDuration={6000}
          onClose={() =>
            setSnackBarInfo({
              isOpen: false,
              message: snackBarInfo.message,
              type: snackBarInfo.type,
              severity: 'success'
            })
          }
          sx={{ backgroundColor: 'transparent' }}
        >
          <div>
            <Alert
              severity={snackBarInfo.severity}
              sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}
            >
              {snackBarInfo.message}
            </Alert>
          </div>
        </Snackbar>
      )}
      {!isLoading && !isFetching && filteredData && data && data?.rows && data?.rows.length > 0 && (
        <TablePagination
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[3, 6, 10, 20, 50]}
          component='div'
          count={data?.total_threats_count || 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}
    </div>
  )
}

export default CyberTable
