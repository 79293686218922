import React from 'react'
import hitronHeaderImg from './HITRON-pb-lockup-rgb.svg'
import { Box, Typography } from '@mui/material'
import { getColorCode } from '../../../utils/helper'

const FooterSection = ({ className = 'bg-white' }) => {
  return (
    <Box
      className={className}
      position={'sticky'}
      bottom={0}
      left={0}
      width={'100%'}
      padding={'10px 20px'}
      zIndex={'9'}
      borderTop={'2px solid #eff2fc'}
    >
      <Box
        className='font-bold'
        textAlign={'right'}
        paddingRight={5}
        display={'flex'}
        justifyContent={'space-between'}
        alignItems={'center'}
      >
        {/* Powered by Hitron */}
        <Box>
          <Typography color={getColorCode('infoBlue')} fontWeight={600}>
            Version 2.5.0
          </Typography>
        </Box>
        <img src={hitronHeaderImg} alt='' width={'130px'} style={{ marginLeft: 5 }} />
      </Box>
    </Box>
  )
}

export default FooterSection
