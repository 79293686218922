import React from 'react'
import { getColorCode } from '../../../utils/helper'

const OfflineHitron = () => {
  const color = getColorCode('lightblueToGray')

  return (
    <>
      <svg width='80' height='100' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M21 7.00011L3.00003 17.0001L2.61151 16.3008L20.6115 6.30078L21 7.00011Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M8.44906 15.1167H15.85H16.0833H16.3167C17.8 15.1167 19 13.9167 19 12.4333C19 11.4032 18.4186 10.5009 17.5569 10.0568L16.5679 10.6062C17.4761 10.7288 18.1667 11.5008 18.1667 12.4333C18.1667 13.45 17.3333 14.2833 16.3167 14.2833H16.0833H15.85H9.94906L8.44906 15.1167ZM16.1833 9.67589L16.9888 9.2284C16.9543 8.94841 16.8883 8.67812 16.7945 8.42118L16.0529 8.83319C16.1369 9.09974 16.1826 9.38283 16.1833 9.67589ZM6.31101 14.2453C6.43138 14.2702 6.55592 14.2833 6.68333 14.2833H7.88993L6.41391 15.1033C6.03524 15.0657 5.67988 14.9495 5.36433 14.7713L6.31101 14.2453ZM15.5889 7.94701C15.0664 7.27093 14.2482 6.83333 13.3333 6.83333C12.2333 6.83333 11.2167 7.48333 10.75 8.5C10.6667 8.7 10.45 8.8 10.25 8.73333C10.05 8.66667 9.83333 8.63333 9.6 8.63333C8.58333 8.63333 7.68333 9.31667 7.41667 10.3C7.36667 10.5 7.16667 10.6333 6.95 10.6C6.86667 10.5833 6.78334 10.5833 6.68334 10.5833H6.68333C5.66667 10.5833 4.83333 11.4167 4.83333 12.4333C4.83333 12.9032 5.01133 13.3339 5.30317 13.6613L4.55938 14.0745C4.20861 13.621 4 13.0518 4 12.4333C4 10.95 5.2 9.75 6.66667 9.75H6.71667C7.18333 8.58333 8.31667 7.8 9.6 7.8C9.78333 7.8 9.96667 7.81667 10.15 7.85C10.8 6.71667 12.0167 6 13.3333 6C14.5661 6 15.6579 6.60648 16.3266 7.53716L15.5889 7.94701Z'
          fill={color}
        />
      </svg>
    </>
  )
}

export default OfflineHitron
