import * as React from 'react'
import './styles.scss'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import { Box, Button, Checkbox, Snackbar, styled } from '@mui/material'
import TablePagination from '@mui/material/TablePagination'
import { ChangeEvent, useEffect, useState } from 'react'
// import { ThunkDispatch } from 'redux-thunk'
// import { useDispatch } from 'react-redux'
// import { AnyAction } from 'redux'
import { Column } from '../../models/CustomerTable'
import { Customer } from '../../models/Xcpem'
import { CustomTable, CustomTableHeaderRow, CustomTableRow } from './customers-tables'
// import { Empty, Spin } from 'antd'
import { useScreenWidth } from '../../hook/useScreenWidth'
// import EmptyData from '../../common-images/app-logo/customer-images/EmptyData'
// import { CustomersRootState } from '../../redux/customerStatistics/types'
// import NoDataFound from '../NoDataFound'
import Loader from '../Loader'
// import SessionTimeOutModal from '../SessionTimeOutModal'
import { getColorCode } from '../../utils/helper'
import { Edit } from 'iconsax-react'
// import { useNavigate } from 'react-router'
import CustomerModal from './CustomerModal'
// import AddDevice from '../../Pages/MSO/AddDevice'
import MsoAddEdit from '../../Pages/MSO/MsoAddEdit'
// import DeviceDetails from '../../Pages/MSO/DeviceDetails'
import MsoDetails from '../../Pages/MSO/MsoDetails'
import { useSessionContex } from '../../SessionContex'
import { SnackBarProps } from '../Device-table/Provisioning'
import MuiAlert from '@mui/material/Alert'
import DeleteMSO from '../../Pages/MSO/DeleteMSO'
import { Modal } from 'antd'

const StyledTableCell = styled(TableCell)`
  && {
    font-size: 16px;
    background: transparent;
    color: #282828 !important;
    font-weight: 500;
    padding: 9px 16px;
  }
`
function Alert(props: any) {
  return <MuiAlert elevation={6} variant='filled' {...props} />
}

const CustomerTable = ({ filteringValue, customersStats, customersStatsLoading, count }: any) => {
  // const dispatch: ThunkDispatch<CustomersRootState, unknown, AnyAction> = useDispatch()
  const { setReCall } = useSessionContex()

  const screenWidth = useScreenWidth()
  // const navigate = useNavigate()
  // pagination
  const [offset, setOffset] = useState<number>(0)
  const [limit, setLimit] = useState<number>(10)
  const [currentPage, setCurrentPage] = useState<number>(0)
  const [customers, setCustomers] = useState<Customer[]>([])
  const [customerOpenModal, setCustomerOpenModal] = useState<boolean>(false)
  const [deleteModal, setDeleteModal] = useState<boolean>(false)
  const [deviceOpenModal, setDeviceOpenModal] = useState<boolean>(false)
  const [edit, setEdit] = useState<boolean>(false)
  const [editData, setEditData] = useState<any>({})
  const [deviceDetail, setDeviceDetail] = useState<any>({})
  const [displaySearch, setDisplaySearch] = useState(false)

  const [width, setWidth] = useState<any>({
    small: '150px',
    normal: '200px',
    regular: '250px',
    large: '300px',
    extraLarge: '350px'
  })
  const [snackBarInfo, setSnackBarInfo] = useState<SnackBarProps>({
    isOpen: false,
    message: '',
    type: 'Closed'
  })

  useEffect(() => {
    if (!screenWidth) return
    if (screenWidth > 2880) {
      setWidth({
        small: '210px',
        normal: '260px',
        regular: '400px',
        large: '450px',
        extraLarge: '500px'
      })
    } else if (screenWidth > 1780) {
      setWidth({
        small: `${(screenWidth * 10) / 100}px`,
        normal: `${(screenWidth * 10) / 100}px`,
        regular: `${(screenWidth * 14) / 100}px`,
        large: `${(screenWidth * 15) / 100}px`,
        extraLarge: `${(screenWidth * 20) / 100}px`
      })
    } else {
      setWidth({
        small: '150px',
        normal: '200px',
        regular: '250px',
        large: '300px',
        extraLarge: '350px'
      })
    }
  }, [screenWidth])

  const columns: any = [
    {
      id: 'white_label',
      label: 'MSO Name',
      minWidth: width.extraLarge,
      align: 'left'
    },
    {
      id: '	customer_address',
      label: 'MSO Address',
      minWidth: width.large,
      align: 'left'
    },
    {
      id: 'customer_contact_person',
      label: 'Contact Name',
      minWidth: width.regular,
      align: 'left'
    },
    {
      id: 'customer_email',
      label: 'Contact Email',
      minWidth: width.extraLarge,
      align: 'left'
    },
    {
      id: 'customer_phone',
      label: 'Contact Mobile',
      minWidth: width.regular,
      align: 'left'
    },
    {
      id: '_',
      label: 'Devices Count',
      minWidth: width.regular,
      align: 'left'
    }
  ]

  useEffect(() => {
    setCustomers([])
    setOffset(0)
    setLimit(10)
    setCurrentPage(0)
  }, [])

  const handleChangePage = (event: unknown, newPage: number) => {
    setCurrentPage(newPage)
    const newOffset = newPage * limit
    setOffset(newOffset)
  }

  const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) => {
    setLimit(parseInt(event.target.value))
    setCurrentPage(0)
    setOffset(0)
  }

  const EmptyMessage = (filterValue: string) => {
    switch (filterValue) {
      case 'AllDevices':
        return 'There are No Devices that are registered at the momentd'
      case 'AllCustomer':
        return 'There are No Customers / MSOs registered at the moment'
      case 'Online':
        return 'There are No Online devices at the moment'
      case 'Offline':
        return 'There are No Offline devices at the moment'
      case 'notRegistered':
        return 'There are No non registered devices at the moment'
      case 'needUpdate':
        return 'There are No devices which requires FW updates at the moment'
      default:
        return 'No Data found'
    }
  }

  useEffect(() => {
    if (!!customersStats && customersStats.length > 0) {
      const currentPageData = customersStats.slice(offset, offset + limit)
      setCustomers(currentPageData)
    }
  }, [customersStats, offset, limit])

  // checkbox logic
  const [selectAll, setSelectAll] = useState<any>(false)
  const [checkedArray, setCheckedArray] = useState<any>([])

  const handleChange = (event: any, data: any) => {
    if (event.target.checked) {
      const newArray = [...checkedArray, data]
      setCheckedArray([...checkedArray, data])
      if (newArray.length === 10) {
        setSelectAll(true)
      } else {
        setSelectAll(false)
      }
    } else {
      const newData: any = checkedArray.filter((obj: any) => obj !== data)
      setSelectAll(false)
      setCheckedArray(newData)
    }
  }
  const handleChangeSelectAll = (event: any) => {
    setSelectAll(event.target.checked)
    if (event.target.checked) {
      setCheckedArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
    } else {
      setCheckedArray([])
    }
  }

  const selectButtonClick = () => {
    setSelectAll(!selectAll)
    if (!selectAll) {
      setCheckedArray([0, 1, 2, 3, 4, 5, 6, 7, 8, 9])
    } else {
      setCheckedArray([])
    }
  }

  return (
    <div style={{ overflow: 'hidden', width: '100%' }}>
      <Snackbar
        open={snackBarInfo.isOpen}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        autoHideDuration={6000}
        onClose={() =>
          setSnackBarInfo({
            isOpen: false,
            message: snackBarInfo.message,
            type: snackBarInfo.type
          })
        }
        sx={{ backgroundColor: 'transparent' }}
      >
        <div>
          <Alert severity='success' sx={{ color: getColorCode('white'), background: getColorCode(snackBarInfo.type) }}>
            {snackBarInfo.message}
          </Alert>
        </div>
      </Snackbar>
      <Box display={'flex'} justifyContent={'end'}>
        <Button
          variant='contained'
          size='medium'
          sx={{
            background: getColorCode('infoBlue'),
            margin: '10px 20px 0px 10px',
            '&:hover': {
              backgroundColor: getColorCode('infoBlue')
            }
          }}
          onClick={() => {
            setCustomerOpenModal(true)
            setEdit(false)
          }}
        >
          Add MSO
        </Button>
      </Box>
      <TableContainer
        sx={{
          scrollbarWidth: 'none',
          msOverflowStyle: 'none',
          overflowX: 'auto',

          '&::-webkit-scrollbar': {
            width: 0,
            height: 0
          }
        }}
      >
        <CustomTable className='table-wrapper' stickyHeader aria-label='sticky table'>
          {customersStatsLoading ? (
            <Loader />
          ) : !!customersStats && customersStats.length !== 0 ? (
            <>
              <TableHead>
                <CustomTableHeaderRow sx={{ borderRadius: 15, height: '60px' }}>
                  {/* <TableCell
                    sx={{
                      fontWeight: '600',
                      background: 'transparent'
                    }}
                  >
                    <Checkbox
                      checked={selectAll}
                      onChange={handleChangeSelectAll}
                      inputProps={{ 'aria-label': 'controlled' }}
                    />
                  </TableCell> */}
                  {columns.map((column: Column) => (
                    <TableCell
                      key={column.id}
                      align={column.align}
                      sx={{
                        fontWeight: '600',
                        minWidth: column.minWidth,
                        maxWidth: column.maxWidth,
                        background: 'transparent'
                        // textTransform: 'uppercase'
                      }}
                    >
                      {column.label}
                    </TableCell>
                  ))}
                  {/* <TableCell
                    sx={{
                      fontWeight: '600',
                      background: 'transparent'
                    }}
                  >
                    Action
                  </TableCell> */}
                </CustomTableHeaderRow>
              </TableHead>
              <TableBody>
                {customers.map((device: any, index: number) => {
                  const colorCode = getColorCode('orangeBlue')
                  const gwCount: any =
                    count && count.length > 0 ? count?.filter((obj: any) => obj.white_label === device.white_label) : []
                  const displayCount = gwCount && gwCount.length !== 0 ? gwCount[0].gw_count : '-'

                  return (
                    <CustomTableRow
                      styledcolor={colorCode}
                      key={`device_row${index}`}
                      onClick={() => {
                        setDeviceOpenModal(true)
                        setDeviceDetail(device)
                      }}
                    >
                      {/* <StyledTableCell>
                        <Checkbox
                          checked={checkedArray?.includes(index)}
                          onChange={(e: any) => handleChange(e, index)}
                          inputProps={{ 'aria-label': 'controlled' }}
                          onClick={e => e.stopPropagation()}
                        />
                      </StyledTableCell> */}
                      <StyledTableCell>{device.white_label || '-'}</StyledTableCell>
                      <StyledTableCell>
                        <Box
                          sx={{ width: '250px', textOverflow: 'ellipsis', overflow: 'hidden' }}
                          title={device.customer_address || '-'}
                        >
                          {device.customer_address || '-'}
                        </Box>
                      </StyledTableCell>
                      <StyledTableCell>{device.customer_contact_person || '-'}</StyledTableCell>
                      <StyledTableCell>{device.customer_email || '-'}</StyledTableCell>
                      <StyledTableCell> {device.customer_phone || '-'}</StyledTableCell>
                      <StyledTableCell>{displayCount}</StyledTableCell>
                      {/* <StyledTableCell>
                        <Edit
                          size={16}
                          // color={'#282828'}
                          className='m-2 cursor-pointer'
                          onClick={(e: any) => {
                            e.stopPropagation()
                            e.preventDefault()
                            // navigate('/mso/edit', { state: device })
                            setCustomerOpenModal(true)
                            setEdit(true)
                            setEditData(device)
                          }}
                        />
                      </StyledTableCell> */}
                    </CustomTableRow>
                  )
                })}
              </TableBody>
            </>
          ) : (
            <div
              className='d-flex justify-center items-center text-lg mt-2'
              style={{ color: getColorCode('redToBlue') }}
            >
              {/* <NoDataFound image={<EmptyData />} message={EmptyMessage(filteringValue)} /> */}

              {EmptyMessage(filteringValue)}
            </div>
          )}
        </CustomTable>
      </TableContainer>
      {/* <Box margin={2}>
        <Button
          size='medium'
          variant='outlined'
          sx={{ color: getColorCode('infoBlue'), borderColor: getColorCode('infoBlue') }}
          onClick={selectButtonClick}
        >
          Select All
        </Button>
      </Box> */}
      {!customersStatsLoading && !!customersStats && customersStats.length !== 0 && (
        <TablePagination
          className='customers_footer'
          sx={{ marginRight: 0 }}
          rowsPerPageOptions={[6, 10, 20, 50]}
          component='div'
          count={!!customersStats ? customersStats.length : 0}
          rowsPerPage={limit}
          page={currentPage}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      )}

      {customerOpenModal && (
        <CustomerModal
          open={customerOpenModal}
          handleClose={() => {
            setCustomerOpenModal(false)
            setEditData({})
          }}
        >
          <MsoAddEdit
            handleClose={() => {
              setCustomerOpenModal(false)
              setEditData({})
            }}
            setSnackBarInfo={setSnackBarInfo}
            edit={edit}
            editData={editData}
            deviceOpenModal={deviceOpenModal}
            setDeviceOpenModal={setDeviceOpenModal}
            setReCall={setReCall}
          />
        </CustomerModal>
      )}
      {deleteModal && (
        <Modal open={deleteModal} footer={null} closable={false} width={600} centered={true} keyboard={true}>
          {/* <CustomerModal
            open={deleteModal}
            handleClose={() => {
              setDeleteModal(false)
              setEditData({})
            }}
          > */}
          <DeleteMSO
            handleClose={() => {
              setDeleteModal(false)
              setEditData({})
            }}
            displaySearch={displaySearch}
            setSnackBarInfo={setSnackBarInfo}
            editData={editData}
            setDeviceOpenModal={setDeviceOpenModal}
            setReCall={setReCall}
          />
          {/* </CustomerModal> */}
        </Modal>
      )}
      {deviceOpenModal && (
        <CustomerModal
          open={deviceOpenModal}
          handleClose={() => {
            setDeviceOpenModal(false)
            setDisplaySearch(false)
          }}
          width={'70%'}
        >
          <MsoDetails
            handleClose={() => {
              setDeviceOpenModal(false)
            }}
            setDisplaySearch={setDisplaySearch}
            displaySearch={displaySearch}
            setCustomerOpenModal={setCustomerOpenModal}
            details={deviceDetail}
            setDeleteMSOModal={setDeleteModal}
            setEdit={setEdit}
            setEditData={setEditData}
            setSnackBarInfo={setSnackBarInfo}
            setReCall={setReCall}
            setMSODetailOpenModal={setDeviceOpenModal}
          />
        </CustomerModal>
      )}
    </div>
  )
}

export default CustomerTable
