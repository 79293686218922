import { FC, useEffect, useState } from 'react'
import DownloadSpeedChart from './download-speed-chart'
import UploadSpeedChart from './upload-speed-chart'
import SpeedTestHistoryChart from './speed-test-history-chart'
import moment from 'moment'
import { useSpeedtest } from '../../../hook/useSpeedtest'
import Loader from '../../../components/Loader'
import { useSessionContex } from '../../../SessionContex'
import dayjs from 'dayjs'
// import utc from 'dayjs/plugin/utc'
// import timezone from 'dayjs/plugin/timezone'
import { DocumentDownload } from 'iconsax-react'
interface Props {
  gwuid: string
  dataDevice: any
  setProcessingSpeedtestCmd?: any
  processingSpeedtestCmd?: any
  setActiveId?: any
  gatewayInfo?: any
}

const SpeedTestCharts: FC<Props> = ({
  gwuid,
  setProcessingSpeedtestCmd,
  processingSpeedtestCmd,
  dataDevice,
  setActiveId,
  gatewayInfo
}) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()

  const [dataFilter, setDataFilter] = useState<'day' | 'isoWeek'>('day')
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()

  const [isPageLoader, setIsPageLoader] = useState(true)
  // const defaultFilter = moment().startOf(dataFilter).toISOString()

  const defaultFilter =
    dataFilter === 'day'
      ? gatewayInfo && !gatewayInfo?.is_online
        ? dayjs.utc(dayjs.tz(dayjs(gatewayInfo?.last_seen).startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss.SSSSSS')
        : dayjs.utc(dayjs.tz(dayjs().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss.SSSSSS')
      : gatewayInfo && !gatewayInfo?.is_online
      ? dayjs
          .utc(dayjs.tz(dayjs(gatewayInfo?.last_seen), timeZone).subtract(168, 'hours'))
          .format('YYYYMMDDTHH:mm:ss.SSSSSS')
      : dayjs.utc(dayjs.tz(dayjs(), timeZone).subtract(168, 'hours')).format('YYYYMMDDTHH:mm:ss.SSSSSS')
  // const defaultFilter = dataFilter === 'isoWeek' ? '2023-10-01T17:00:00.000Z' : '2023-10-07T17:00:00.000Z'

  const { data, isFetching, refetch } = useSpeedtest(gwuid, defaultFilter)

  useEffect(() => {
    if (!isFetching && data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data, isFetching])

  useEffect(() => {
    gwuid && gwuid !== '' && refetch()
    setTimeout(() => {
      setIsPageLoader(false)
      setProcessingSpeedtestCmd(false)
    }, 1500)
  }, [dataFilter])

  useEffect(() => {
    if (!isPageLoader) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isPageLoader])

  useEffect(() => {
    if (processingSpeedtestCmd && gwuid && gwuid !== '') {
      setIsPageLoader(true)
      gwuid && gwuid !== '' && refetch()
      setTimeout(() => {
        setIsPageLoader(false)
        setProcessingSpeedtestCmd(false)
      }, 1500)
    }
  }, [gwuid, processingSpeedtestCmd])

  const handleDataFilter = (filter: 'day' | 'isoWeek') => {
    if (dataFilter !== filter) {
      setDataFilter(filter)
      setIsPageLoader(true)
      // setActiveId('')
    }
  }

  return (
    <div className='flex flex-col'>
      {isPageLoader ? (
        <>
          <Loader />
        </>
      ) : (
        <>
          {dataDevice && dataDevice?.length > 0 && (
            <div className='w-full d-flex py-2 justify-end items-center'>
              <span
                onClick={() => handleDataFilter('day')}
                className={
                  dataFilter === 'day'
                    ? `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'hitron-active-filter'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'customer-active-filter'
                          : 'active-filter'
                      } cursor-pointer`
                    : `inactive-filter cursor-pointer`
                }
              >
                Today
              </span>
              <span
                onClick={() => handleDataFilter('isoWeek')}
                className={
                  dataFilter === 'isoWeek'
                    ? `${
                        process.env.REACT_APP_COLOR === 'hitron'
                          ? 'hitron-active-filter'
                          : process.env.REACT_APP_COLOR === 'customer'
                          ? 'customer-active-filter'
                          : 'active-filter'
                      } cursor-pointer`
                    : 'inactive-filter cursor-pointer'
                }
              >
                Last 7 Days
              </span>
              {process.env.REACT_APP_MODE !== 'hitron' ? (
                <span
                  onClick={() => {
                    if (data && data.length !== 0) {
                      const headers = ['average_download_speed', 'avg_upload_speed', 'ping', 'server_name', 'timestamp']
                      const displayHeaders = [
                        'Download Speed (mbps)',
                        'Upload Speed (mbps)',
                        'Ping',
                        'Server Name',
                        'Timestamp'
                      ]
                      // Create header row
                      let csv = displayHeaders.join(',') + '\n'
                      // Create rows
                      for (let item of data) {
                        const row = headers
                          .map(header =>
                            header === 'timestamp'
                              ? moment(item.speedtest.params[header] + 'Z')
                                  .local()
                                  .format(`${process.env.REACT_APP_DATE_FORMAT} hh:mm A`)
                              : header === 'server_name'
                              ? item.speedtest.params[header].replace(',', ' ')
                              : item.speedtest.params[header]
                          )
                          .join(',')
                        csv += row + '\n'
                      }
                      let blob = new Blob([csv], { type: 'text/csv' })
                      let url = window.URL.createObjectURL(blob)
                      let a = document.createElement('a')
                      a.href = url
                      a.download = 'speedTest.csv'
                      document.body.appendChild(a)
                      a.click()
                    }
                  }}
                  title='Export'
                  style={{
                    borderRadius: '10px',
                    padding: '8px',
                    background: data?.length === 0 ? '#ccc' : '#fff',
                    cursor: data?.length === 0 ? 'default' : 'pointer',
                    margin: '0 8px'
                  }}
                  aria-disabled={data?.length === 0}
                >
                  <DocumentDownload />
                </span>
              ) : (
                <></>
              )}
            </div>
          )}
          {data?.length === 0 && (
            <div className='text-center text-[#6B7A9F] font-sf-regular text-lg animate-pulse py-5'>
              {/* Please use latest firmware to see speed test results / run your first speed test */}
              There are no speed test results to display. Please run the test to see the latest results
            </div>
          )}
          {data !== undefined && data.length > 0 && (
            <DownloadSpeedChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  if (item.speedtest.params && item.speedtest.result === 'OK') {
                    return item.speedtest.params
                  } else {
                    return item.speedtest
                  }
                })}
              categories={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  return item.timestamp
                })
                .reverse()}
            />
          )}
          {data !== undefined && data.length > 0 && (
            <UploadSpeedChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                ?.map((item: any) => {
                  if (item.speedtest.params) {
                    item.speedtest.params.timestamp = item.timestamp
                    return item.speedtest.params
                  } else {
                    return item.speedtest
                  }
                })}
              categories={data
                ?.filter((item: any) => !item.speedtest.cmd)
                .map((item: any) => {
                  return item.timestamp
                })
                .reverse()}
            />
          )}
          {data?.length > 0 && data !== undefined && (
            <SpeedTestHistoryChart
              data={data
                ?.filter((item: any) => !item.speedtest.cmd)
                ?.map((item: any) => {
                  if (item.speedtest.params) {
                    // item.speedtest.timestamp = item.timestamp
                    return item.speedtest.params
                  } else {
                    item.speedtest.timestamp = item.timestamp
                    return item.speedtest
                  }
                })
                .reverse()}
              dataFilter={dataFilter}
            />
          )}
        </>
      )}
    </div>
  )
}

export default SpeedTestCharts
