import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Customer, DashboardStats } from '../models/Xcpem'
import { KEYCLOAK_CONFIG, apiUrl } from '../app-config'
import xcpemSec from '../axiosInstances/xcpemSec'
import { notifyError } from '../components/Toaster'
import { useState } from 'react'
import axios from 'axios'

export const useAllCustomers = (offset: number = 0, limit: number = 6): {} => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['customers'],
    async () => {
      const endpoint = `${apiUrl}/webapp_white_labels_list?limit=${limit}&offset=${offset}`
      try {
        const response = await xcpemSec.get<Customer[]>(endpoint)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Labels list: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGetCustomerAndCount = (): any => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['customersWithCount'],
    async () => {
      const endpoint = `${apiUrl}/webapp_gw_count_per_white_label`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        // console.log('========@@', response.data)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Labels list with gw count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}
export const useGetOnlineCount = (): any => {
  const { data, isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['OnlineConTypeCount'],
    async () => {
      const endpoint = `${apiUrl}/rpc/online_gw_conn_types_count`
      try {
        const response = await xcpemSec.get<any>(endpoint)
        // console.log('========@@', response.data)
        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Online gw conn types count: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0
    }
  )

  return { data, isLoading, error, isFetching, isFetched, refetch }
}

const host = `https://${process.env.REACT_APP_IOTCORE}`
const baseUrl = `${host}`
const access_token = localStorage.getItem('access_token')

const addMSO = async (payload: any) => {
  const response = await xcpemSec.post(`${baseUrl}/webapp_white_labels_list`, payload)

  return response.data
}

export const useAddMSO = () => {
  return useMutation(addMSO)
}
const keyClockAddMSO = async (payload: any) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups`

  const response = await axios.post(keyClockURL, payload, {
    headers: { Authorization: `Bearer ${access_token}` }
  })

  return response.data
}

export const useKeyClockAddMSO = () => {
  return useMutation(keyClockAddMSO)
}

const keyClockDeleteMSO = async (group_name: any) => {
  const keyClockURL = `${KEYCLOAK_CONFIG.URL}/api/${KEYCLOAK_CONFIG.REALM}/groups/${group_name}`

  const response = await axios.delete(keyClockURL, {
    headers: { Authorization: `Bearer ${access_token}` }
  })

  return response.data
}

export const useKeyClockDeleteMSO = () => {
  return useMutation(keyClockDeleteMSO)
}

const usePostSendMail = async (mailPayload: any) => {
  // const response = await xcpemSec.post(`${baseUrl}/api/wifi/reset-wifi-password`, mailPayload)
  let url = `${baseUrl}/api/v1/account/mso-created/mail`
  if (process.env.REACT_APP_REALM === 'Hitron-staging') {
    url = `${baseUrl}/api/v1/notification/account/mso-created`
  }
  const response = await xcpemSec.post(`${url}`, mailPayload, {
    headers: {
      Authorization: `Bearer ${access_token}`
    }
  })
  return response.data
}

export const useSendMail = () => {
  return useMutation(usePostSendMail)
}
export const updateMSO = () => {
  const putRequest = async ({ values, id }: any) => {
    const response = await xcpemSec.patch(`${baseUrl}/webapp_white_labels_list?id=eq.${id}`, values)
    return response.data
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { mutate, isLoading, error } = useMutation(putRequest)

  return { mutate, isLoading, error }
}

export const useGetMSO = (id: any) => {
  const [data, setData] = useState<any | undefined>([])
  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['get-MSO-detail'],
    async () => {
      const endpoint = `${baseUrl}/webapp_white_labels_list?id=eq.${id}`
      try {
        const response = await xcpemSec.get<any>(endpoint)

        return response.data
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`MSO: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['get-MSO-detail'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

export const useDeleteMSO = (id: any) => {
  const [data, setData] = useState<any | undefined>('')
  const { isLoading, error, isFetching, isFetched, refetch, isError } = useQuery(
    ['delete-MSO'],
    async () => {
      const endpoint = `${baseUrl}/webapp_white_labels_list?id=eq.${id}`
      try {
        const response = await xcpemSec.delete<any>(endpoint)

        return response?.status
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          return error
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['delete-MSO'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, isError, refetch: handleRefetch }
}

export const useDeviceData = ({
  white_label,
  offset,
  limit,
  inputValue
}: any): {
  data: any[] | undefined | any
  isLoading: boolean
  error: any | null
  isFetching: boolean
  isFetched: boolean
  refetch: () => void
} => {
  const [data, setData] = useState<any | undefined>(undefined)

  const { isLoading, error, isFetching, isFetched, refetch } = useQuery(
    ['device-data'],
    async () => {
      const endpoint = `${apiUrl}/webapp_dashboard_all_devices?white_label=eq.${white_label}&offset=${offset}&limit=${limit}&or=(email.ilike.*${encodeURIComponent(
        inputValue
      )}*,gw_uid.ilike.*${inputValue}*)`
      try {
        const response = await xcpemSec.get<any[]>(endpoint, {
          headers: {
            Prefer: 'count=exact' // Add Prefer: count=exact header
          }
        })
        const newData = [{ data: response.data }, { total: response.headers['content-range'] }]

        return newData
      } catch (error: any) {
        if (error?.response?.status === 401) {
          return error?.response?.status
        } else {
          notifyError(`Devices: ${error?.response?.data?.message || error?.message}`)
        }
      }
    },
    {
      enabled: false, // Set initial query to be disabled
      cacheTime: 0,
      staleTime: 0,
      retry: 0,
      onSuccess: newData => {
        setData(newData) // Update the state with the new data
      }
    }
  )
  const queryClient = useQueryClient()

  const handleRefetch = async () => {
    setData(undefined)
    await queryClient.invalidateQueries(['device-data'] as readonly unknown[])

    refetch()
  }
  return { data, isLoading, error, isFetching, isFetched, refetch: handleRefetch }
}

const addDevice = async (payload: any) => {
  try {
    const response = await xcpemSec.post(`${baseUrl}/rpc/webapp_gateway_bulk_white_labeling`, payload)
    return response.data
  } catch (error: any) {
    if (error?.response?.status === 401) {
      return error?.response?.status
    } else {
      notifyError(`Add Device: ${error?.response?.data?.message || error?.message}`)
      throw new Error(error?.response?.data?.message || error?.message)
    }
  }
}

export const useAddDevice = () => {
  return useMutation(addDevice)
}
