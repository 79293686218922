import React, { FC, useEffect, useState } from 'react'
// import ReactApexChart from 'react-apexcharts'
import { Box, FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import { useConditionalParentalConnectedDevices } from '../../../../hook/parental-control/useParental'
import { currentISOTime, getColorCode, startOfMonth, startOfWeek } from '../../../../utils/helper'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import './styles.scss'
import LoadingSpinner from '../../../../common-images/app-logo/LoadingSpinner/LoadingSpinner'
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger
} from '../../../../Layouts/AnimatedLayout/DropDown/dropdown-menu'
import DownSideArrow from '../../../../common-images/app-logo/downside-arrow'
import dayjs from 'dayjs'
import moment from 'moment'
import { useSessionContex } from '../../../../SessionContex'

interface Props {
  email: string
  isFilter?: boolean
}

const ConnectedDevicesCommon: FC<Props> = ({ email, isFilter }) => {
  const { timeZone }: any = Intl.DateTimeFormat().resolvedOptions()
  const [filterLabel, setFilterLabel] = useState<string>('today')
  const { openModal, setOpenModal, reCall, setReCall } = useSessionContex()
  const [hours] = useState<number>(dayjs().tz(timeZone).diff(dayjs().tz(timeZone).startOf('day'), 'hour'))
  const [fromDate, setFromDate] = useState<any>(
    moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss')
  )

  const { data, isLoading, refetch, isFetching, error } = useConditionalParentalConnectedDevices(fromDate, email)
  useEffect(() => {
    refetch()
  }, [])
  useEffect(() => {
    if (reCall) {
      refetch()
    }
  }, [reCall])

  useEffect(() => {
    if (!isFetching) {
      if (reCall) {
        setTimeout(() => {
          setReCall(false)
        }, 500)
      }
    }
  }, [isFetching])

  useEffect(() => {
    refetch()
  }, [email, fromDate])
  useEffect(() => {
    if (data === 401) {
      !openModal && setOpenModal(true)
    }
  }, [data])
  const connected = data?.devices?.filter((item: any) => item.is_active).length || 0
  const disconnected = data?.devices?.filter((item: any) => !item.is_active).length || 0

  const options: Highcharts.Options = {
    chart: {
      type: 'pie'
    },
    title: {
      text: ''
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        dataLabels: {
          enabled: false,
          format: '<b>{point.name}</b>: {point.percentage:.1f}%'
        },
        colors: [getColorCode('Closed'), getColorCode('redToGray')],
        showInLegend: true
      }
    },
    legend: {
      align: 'center',
      verticalAlign: 'bottom', // Align legends in the middle
      layout: 'horizontal',
      itemMarginTop: 10, // Add spacing above each legend item
      itemMarginBottom: 10,
      itemStyle: {
        fontSize: '18px', // Adjust the font size
        fontFamily: 'Arial, sans-serif' // Adjust the font family
      },
      padding: 30
    },
    series: [
      {
        name: 'Count',
        data: [
          {
            name: 'Online Devices',
            y: connected
          },
          {
            name: 'Offline Devices',
            y: disconnected
          }
        ],
        type: 'pie'
      }
    ],
    credits: {
      enabled: false
    }
  }
  const CartData = [
    {
      name: 'Today',
      value: 'today'
    },
    {
      name: 'Last 7 Days',
      value: 'thisWeek'
    },
    {
      name: 'Last 30 Days',
      value: 'thisMonth'
    }
  ]
  const handleFilterChange = (e: any) => {
    if (e.target.value === 'thisMonth') {
      setFromDate(moment.utc(moment.tz(moment().subtract(1, 'month'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'thisWeek') {
      setFromDate(moment.utc(moment.tz(moment().subtract(7, 'days'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    } else if (e.target.value === 'today') {
      setFromDate(moment.utc(moment.tz(moment().startOf('day'), timeZone)).format('YYYYMMDDTHH:mm:ss'))
    }
    setFilterLabel(e.target.value)
  }
  return (
    <div className={`w-full bg-white rounded-[10px] text-center`}>
      <div
        className={` w-full ${
          isFilter ? 'inline-flex justify-between items-center' : 'text-center'
        } time-supervision-inner-wrapper p-2`}
      >
        {/*<ReactApexChart options={chartOptions as any} series={chartOptions.series} type="radialBar" height={300}/>*/}
        <span className='text-[24px] font-sf-bold time-supervision-title pt-2'>Devices Status</span>
        {isFilter && (
          <div className='w-40 mt-2  text-left'>
            <FormControl fullWidth>
              <InputLabel id='demo-simple-select-label'>Filter</InputLabel>
              <Select
                labelId='demo-simple-select-label'
                id='demo-simple-select'
                value={filterLabel}
                label='Filter'
                onChange={handleFilterChange}
                className='text-uppercase'
              >
                {CartData.map((option: any, index: number) => {
                  return (
                    <MenuItem value={option.value} className='text-uppercase' key={`option_${index}_${option}`}>
                      {option.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          </div>
          // <DropdownMenu>
          //   <DropdownMenuTrigger className='dropdown-btn flex'>
          //     {filterLabel}
          //     <span className='down-arrow'>
          //       <DownSideArrow />
          //     </span>
          //   </DropdownMenuTrigger>
          //   <DropdownMenuContent className='drop-down'>
          //     {CartData.map((option: any, index: number) => {
          //       return (
          //         <DropdownMenuItem
          //           className='drop-down-option'
          //           key={`option_${index}_${option}`}
          //           onClick={(e: any) => handleFilterChange(option)}
          //         >
          //           {option.name}
          //         </DropdownMenuItem>
          //       )
          //     })}
          //   </DropdownMenuContent>
          // </DropdownMenu>
        )}
      </div>
      {isFetching ? (
        <Box display={'flex'} justifyContent={'center'} alignItems={'center'} height={'200px'}>
          <LoadingSpinner />
        </Box>
      ) : (data && data?.devices && data?.devices?.length !== 0) || error !== null ? (
        <HighchartsReact highcharts={Highcharts} options={options} />
      ) : (
        <Box marginLeft={2.5} paddingBottom={4} color={getColorCode('redToBlue')}>
          Device status data not found
        </Box>
      )}

      {/*        <div className='flex flex-col gap-y-3'>*/}
      {/*            <span className='inline-flex items-center gap-x-4'>*/}
      {/*                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">*/}
      {/*  <path fill-rule="evenodd" clip-rule="evenodd" d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z" fill="#A3D696"/>*/}
      {/*</svg>*/}
      {/*                Online Devices*/}

      {/*            </span>*/}
      {/*            <span className='inline-flex items-center gap-x-4'>*/}
      {/*                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">*/}
      {/*  <path fill-rule="evenodd" clip-rule="evenodd"*/}
      {/*        d="M7 14C10.866 14 14 10.866 14 7C14 3.13401 10.866 0 7 0C3.13401 0 0 3.13401 0 7C0 10.866 3.13401 14 7 14Z"*/}
      {/*        fill="#FF974C"/>*/}
      {/*</svg>*/}
      {/*                Offline Devices*/}

      {/*            </span>*/}
      {/*        </div>*/}
    </div>
  )
}
export default ConnectedDevicesCommon
