import React from 'react'
import { getColorCode } from '../../../utils/helper'

const OnlineHitron = () => {
  const color = getColorCode('greenToGreen')

  return (
    <>
      <svg width='80' height='100' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M12.9443 13.3579C12.8969 13.3788 12.8533 13.4095 12.8167 13.4502L11.5834 14.6835C11.4167 14.8502 11.4167 15.1169 11.5834 15.2669C11.6716 15.3551 11.7879 15.3966 11.9 15.3914C11.9997 15.3868 12.0961 15.3453 12.1667 15.2669L12.7 14.7335V18.5669C12.7 18.8002 12.8834 18.9835 13.1167 18.9835C13.35 18.9835 13.5334 18.8002 13.5334 18.5669V14.7335L14.0667 15.2669C14.142 15.3421 14.2309 15.3766 14.3334 15.3826C14.3443 15.3832 14.3554 15.3835 14.3667 15.3835C14.4834 15.3835 14.5834 15.3502 14.6667 15.2669C14.8334 15.1002 14.8334 14.8335 14.6667 14.6835L13.4 13.4502C13.3741 13.4242 13.3456 13.4023 13.3156 13.3844C13.2001 13.3156 13.0607 13.3068 12.9443 13.3579Z'
          fill={color}
        />
        <path
          fill-rule='evenodd'
          clip-rule='evenodd'
          d='M10.0672 18.9476C10.0114 18.9726 9.95007 18.9835 9.88331 18.9835C9.81655 18.9835 9.75525 18.9726 9.69941 18.9476C9.65766 18.929 9.61896 18.9025 9.58331 18.8668L8.34998 17.6335C8.18331 17.4835 8.18331 17.2168 8.34998 17.0502C8.43232 16.9587 8.54982 16.9174 8.66664 16.9264C8.76262 16.9337 8.85814 16.975 8.93331 17.0502L9.46664 17.5835V13.7502C9.46664 13.5168 9.64998 13.3335 9.88331 13.3335C10.1166 13.3335 10.3 13.5168 10.3 13.7502V17.5835L10.8333 17.0502C10.9085 16.975 11.004 16.9337 11.1 16.9264C11.2168 16.9174 11.3343 16.9587 11.4166 17.0502C11.5833 17.2002 11.5833 17.4668 11.4166 17.6335L10.1833 18.8668L10.1657 18.8492M11.399 17.6158C11.3989 17.616 11.3991 17.6157 11.399 17.6158V17.6158ZM10.0672 18.9476C10.109 18.929 10.1477 18.9025 10.1833 18.8668L10.0672 18.9476Z'
          fill={color}
        />
        <path
          d='M17.0167 9.85C17.0167 9.8 17.0167 9.73333 17.0167 9.68333C17.0167 7.65 15.3667 6 13.3333 6C12.0167 6 10.8 6.71667 10.15 7.85C9.96667 7.81667 9.78333 7.8 9.6 7.8C8.31667 7.8 7.18333 8.58333 6.71667 9.75C6.7 9.75 6.68333 9.75 6.66667 9.75C5.2 9.75 4 10.95 4 12.4333C4 13.9167 5.2 15.1167 6.68333 15.1167H7.15C7.38333 15.1167 7.56667 14.9333 7.56667 14.7C7.56667 14.4667 7.38333 14.2833 7.15 14.2833H6.68333C5.66667 14.2833 4.83333 13.45 4.83333 12.4333C4.83333 11.4167 5.66667 10.5833 6.68333 10.5833C6.78333 10.5833 6.86667 10.5833 6.95 10.6C7.16667 10.6333 7.36667 10.5 7.41667 10.3C7.68333 9.31667 8.58333 8.63333 9.6 8.63333C9.83333 8.63333 10.05 8.66667 10.25 8.73333C10.45 8.8 10.6667 8.7 10.75 8.5C11.2167 7.48333 12.2333 6.83333 13.3333 6.83333C14.9 6.83333 16.1833 8.11667 16.1833 9.68333C16.1833 9.83333 16.1667 9.98333 16.15 10.1167C16.1333 10.2333 16.1667 10.35 16.2333 10.4333C16.3 10.5167 16.4 10.5833 16.5167 10.6C17.45 10.7 18.1667 11.4833 18.1667 12.4333C18.1667 13.45 17.3333 14.2833 16.3167 14.2833H15.85C15.6167 14.2833 15.4333 14.4667 15.4333 14.7C15.4333 14.9333 15.6167 15.1167 15.85 15.1167H16.3167C17.8 15.1167 19 13.9167 19 12.4333C19 11.2 18.1667 10.15 17.0167 9.85Z'
          fill={color}
        />
      </svg>
    </>
  )
}

export default OnlineHitron
